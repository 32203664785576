.p-card {
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 20px;
}
.p-card .p-card-body {
  padding: 0rem;
}

.p-card-login {
  background-color: var(--tertiary-color);
  color: var(--black-color);
}
.responsive-width-dashboard {
  width: 100%;
}
.p-card-login .p-card-body {
  padding: 1rem;
}

.dashboard-card {
  min-width: 8rem !important;

  height: 6rem !important;
}
.dashboard-card .p-card-content {
  font-size: 0.8rem !important;
}
.dashboard-card .p-card-content h5 {
  font-size: 0.9rem;
}
@media screen and (max-width: 1650px) {
  .dashboard-card .p-card-content {
    padding-top: 1rem;
  }
  .dashboard-card .p-card-content h5 {
    font-size: 0.75rem;
  }
  .dashboard-card {
    width: 10rem !important;
  }
}

@media screen and (max-width: 1260px) {
  .dashboard-card {
    width: 4rem !important;
    height: 4rem !important;
  }
  .dashboard-card .p-card-content {
    padding-top: 0.75rem;
    height: 0.75rem;
  }
  .dashboard-card .p-card-content h5 {
    font-size: 0.55rem;
  }
  .facility-container h5 {
    height: 2.2rem;
    overflow: hidden;
  }
}
@media screen and (max-width: 800px) {
  .dashboard-card .p-card-content {
    font-size: 0.5rem !important;
  }
  .dashboard-card .p-card-content h5 {
    font-size: 0.3rem;
  }
}
