/*Input*/
.p-inputtext {
  width: 20rem;

  background-color: #d9d9d9;
  border-radius: 10px;
}
#OrderDateTime {
  width: 20rem;
  background-color: #d9d9d9;
  border-radius: 10px 10px 10px 10px;
}

#OrderDateTime .p-inputtext {
  width: 20rem;
  background-color: #d9d9d9;
  border-radius: 10px 0px 0px 10px;
}

#OrderDateTime .p-button {
  background-color: var(--primary-color);
  border: none;
}

/*DropDown*/
.p-dropdown {
  width: 20rem;
  background-color: #d9d9d9;
  border-radius: 10px;
}
.header-dropdown .p-dropdown-label {
  display: none;
}
.header-dropdown .p-dropdown-trigger {
  color: #f2f2f2;
}

.p-dropdown-filter {
  width: 90%;
}
/* MultiSelect */

.p-multiselect {
  width: 20rem;
  background-color: #d9d9d9;
  border-radius: 10px;
}
