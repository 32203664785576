@import url("https://use.typekit.net/eea5mti.css");

:root {
  --primary-color: #14414c !important;
  --secondary-color: #8c3319 !important;
  --tertiary-color: #d8d3b2 !important;
  --white-color: #f2f2f2 !important;
  --black-color: #353535 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex: 1;
  padding: 20px;
}
html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white-color);
}
.main-content {
  flex: 1;
}
.footer {
  background-color: var(--black-color);
  color: white;
  text-align: center;
  padding: 10px;
}

h1 {
  margin: 0;
  font-weight: 700;
  color: var(--secondary-color);
  font-size: 96px;
}

h2 {
  font-family: "muli", sans-serif;
  margin: 0;
  line-height: 1.5;
  color: var(--black-color);
  font-size: 42px;
  font-weight: 700;
}

h3 {
  margin: 0;
  color: var(--white-color);
  font-size: 32px;
  font-weight: 500;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
  font-size: 20px;
}
p {
  font-family: "Montserrat", sans-serif;
}

/* MenuBar */
.p-menubar {
  background-color: var(--primary-color);
  border: none;
  color: #f2f2f2;
  border-radius: 0 0 10px 10px;
  z-index: 1;
}
.p-menubar-button {
  display: none;
}
.p-menuitem-text {
  color: var(--white-color);
}

.p-menuitem-content:hover {
  background-color: var(--primary-color);
}

/*BreadCrumb*/
.p-breadcrumb {
  background-color: #f2f2f2;
  border: none;
  margin-top: 1em;
  padding: 10px;
  padding-left: 30px;
}
.p-breadcrumb .p-menuitem-text {
  color: #353535;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 4rem !important;
  }
  h2 {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 1650px) {
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.85rem;
  }
  p {
    font-size: 0.8rem;
  }
  .h5Header {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 1260px) {
  h4 {
    font-size: 0.6rem;
  }
  h5 {
    font-size: 0.55rem;
  }
}
