/* Datatable */

.p-datatable-thead > tr > th {
  background-color: var(--tertiary-color);
  color: var(--black-color);
}

.p-column-header-content {
  justify-content: center;
  height: 4rem;
  font-size: large;
  font-weight: bold;
}

.p-sortable-column-icon,
.p-column-filter-menu-button {
  color: var(--black-color);
  margin: 0 0.2em;
}
.p-column-filter {
  margin-left: 0;
}

.p-datatable-table {
  text-align: inherit;
}

.dataTable-home > div > table > thead > tr > th:first-child > div,
.dataTable-home > div > table > thead > tr > th:nth-child(2) > div,
.dataTable-home > div > table > thead > tr > th:nth-child(3) > div {
  justify-content: flex-start !important;
}

.dataTable-Order > div > table > thead > tr > th:nth-child(1) > div,
.dataTable-Order > div > table > thead > tr > th:nth-child(3) > div {
  justify-content: flex-start !important;
}

.dataTable-Order > div > table > thead > tr > th:nth-child(4) > div,
.dataTable-Order > div > table > thead > tr > th:nth-child(6) > div,
.dataTable-Order > div > table > thead > tr > th:nth-child(7) > div {
  justify-content: center !important;
  text-align: center !important;
}

.dataTable-Order > div > table > thead > tr > th > div > span {
  font-size: 0.8rem;
}
