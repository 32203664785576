.p-button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  width: 160px;
  height: 60px;
  border: none;
  border-radius: 3px;
}

.p-button-primary:hover {
  background-color: var(--primary-color);
}

.p-button-secondary {
  background-color: var(--primary-color) !important;
  border: none;
  border-radius: 3px;
  width: 143px;
  height: 60px;
}

.p-button-secondary:hover {
  background-color: var(--secondary-color) !important;
}
.p-button-dataTable {
  background-color: var(--primary-color) !important;
  border: none;
  border-radius: 3px;
  height: 40px;
  width: 120px;
}

.p-button-terciary {
  background-color: #b7ae71 !important;
  border: none;
  border-radius: 3px;
  color: #353535;
  height: 40px;
  width: 120px;
}

.p-button-terciary:hover {
  background-color: #d8d2a9 !important;
}

.p-button-disabled {
  background-color: var(--tertiary-color) !important;
  border: none;
  border-radius: 3px;
  color: var(--secondary-color);
}

/*ToggleButton*/
.p-togglebutton {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.p-button-icon-left {
  background-color: var(--secondary-color);
}

@media screen and (max-width: 1260px) {
  .p-button-primary {
    height: 3rem !important;
    width: 9rem !important;
  }
}
